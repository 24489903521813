import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://a1.phlabshop.com/",
    prepareHeaders: (headers) => {
      const cookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith("_auth="))
        ?.split("=")[1];
      const token = cookieValue ? decodeURIComponent(cookieValue) : null;

      if (token) {
        // Ustawianie nagłówka Authorization
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Product", "User", "Orders"],
  endpoints: (builder) => ({}),
});

// export const apiSlice = createApi({
//   reducerPath: "apiSlice",
//   baseQuery: fetchBaseQuery({ baseUrl: "https://a.phlab.pl/" }),
//   tagTypes: ["Product", "User"],
//   endpoints: (builder) => ({}),
// });
