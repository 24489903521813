import { apiSlice } from "./apiSlice";

import { setUserData } from "./userSlice";

apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "login",
        method: "POST",
        body: data,
      }),
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        // const { data } = await queryFulfilled;
        const { data: result } = await queryFulfilled;
        dispatch(setUserData(result[0].userData));
      },
    }),
  }),
});

export const { useLoginMutation } = apiSlice;
