import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/apiSlice";
import authReducer from "../features/authSlice";
import modal from "../features/modalSlice";
import products from "../features/productsSlice";
import orders from "../features/ordersSlice";

import { setupListeners } from "@reduxjs/toolkit/query/react";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    modal,
    products,
    orders,
  },
  middleware: (getDefaultmiddleware) =>
    getDefaultmiddleware().concat(apiSlice.middleware),
});

setupListeners(store.dispatch);
