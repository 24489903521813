import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAddProductMutation } from "../../features/productsSlice";

import Resizer from "react-image-file-resizer";
import Editor from "../Editor";

import { AiOutlineCloudUpload, AiOutlineLoading } from "react-icons/ai";
import { RiSave3Fill, RiDeleteBin6Line } from "react-icons/ri";

function AddProduct() {
  const { register, handleSubmit, control } = useForm({
    mode: "onBlur",
  });

  const [addProduct, { isLoading }] = useAddProductMutation();

  const [descriptionContent, setDescriptionContent] = useState("");
  const [selectedImage, setSelectedImage] = useState([]);

  const navigate = useNavigate();

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const onSubmit = async (data) => {
    const formData = new FormData();
    selectedImage.forEach((e, i) => formData.append(`image_${i}`, e));
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    await addProduct(formData);
    navigate("/products");
  };

  return (
    <>
      <h1 className="flex items-center justify-between px-4 py-2 mb-4 text-2xl rounded-lg bg-slate-100 text-my-dark-green font-extralight text-my-dark-greeen">
        Dodaj Produkt
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-full gap-x-4">
          <div className="flex-1 p-8 border rounded-3xl border-slate-200">
            <div className="flex items-center justify-center w-full">
              <label className="flex items-center justify-center w-full h-20 py-1 font-bold duration-300 border-2 border-dashed rounded-lg cursor-pointer group text-my-green items-starts bg-slate-100 hover:bg-my-green border-my-green hover:border-white hover:text-white">
                <AiOutlineCloudUpload className="mr-2 text-4xl" />
                <div className="mt-2 text-base leading-normal text-gray-600">
                  Dodaj zdjęcie{" "}
                  <span className="duration-300 transform text-my-green group-hover:text-white">
                    Klik
                  </span>
                </div>
                <input
                  type="file"
                  name="image"
                  className="hidden"
                  accept=".jpg, .png"
                  multiple
                  onChange={(file) => {
                    const files = file.target.files;
                    const promises = [...files].map((el) => {
                      const output = resizeFile(el);
                      return output;
                    });
                    Promise.all(promises).then(
                      async (res) =>
                        await setSelectedImage((prv) => [...prv, ...res])
                    );
                  }}
                />
              </label>
            </div>

            <div className="container grid grid-flow-row grid-cols-5 gap-4 mx-auto mt-8">
              {selectedImage?.map((e, i) => (
                <div
                  key={`${e.name}_${i}`}
                  className="relative flex w-full h-32 bg-slate-200 rounded-2xl hover:border-grey-900 hover:border"
                >
                  <img
                    src={URL.createObjectURL(e)}
                    className="object-cover w-full rounded-2xl"
                    alt="Thumb"
                  />

                  <button
                    onClick={() =>
                      setSelectedImage(
                        selectedImage.filter((item) => item.name !== e.name)
                      )
                    }
                    className="absolute flex items-center justify-center w-12 h-12 text-2xl bg-white rounded-full bottom-2 right-2 text-slate-500"
                  >
                    <RiDeleteBin6Line />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 p-8 border rounded-2xl border-slate-200">
            <div className="flex flex-col items-start justify-start mb-3">
              <label
                className="block mb-2 text-sm font-bold text-my-dark-green"
                htmlFor="category"
              >
                {/* Kategoria: */}
              </label>
              {/* <Controller
                name="category"
                control={control}
                render={() => (
                  <CategoryList register={register} setValue={setValue} />
                )}
              /> */}
              <label className="block mb-2 text-my-dark-green" htmlFor="title">
                Nazwa produktu::
              </label>
              <input
                disabled={isLoading}
                className="w-full p-2 mb-2 text-base leading-4 text-gray-600 placeholder-gray-600 border border-gray-300 rounded outline-none focus:border-my-green"
                name="title"
                id=""
                placeholder="Nazwa Produktu"
                {...register("title", { required: true })}
              />
              <label className="block mb-2 text-my-dark-green" htmlFor="price">
                Cena:
              </label>
              <input
                disabled={isLoading}
                className="w-full p-2 mb-2 text-base leading-4 text-gray-600 placeholder-gray-600 border border-gray-300 rounded outline-none focus:border-my-green"
                name="price"
                type="number"
                id=""
                placeholder="Cena"
                {...register("price", { required: true })}
              />
              <label
                className="block mb-2 text-my-dark-green"
                htmlFor="description"
              >
                Opis produktu:
              </label>
              <Editor name="description" control={control} />
            </div>
            <button
              disabled={isLoading}
              type="submit"
              className="flex items-center justify-center w-full py-2 font-semibold duration-150 border border-transparent rounded-lg text-slate-100 bg-my-dark-green02 hover:bg-lime-700"
            >
              <div>
                <p className="text-base leading-4 ">
                  {isLoading ? (
                    <div className="flex items-center justify-center">
                      <AiOutlineLoading className="mr-2 text-2xl animate-spin" />
                      Wysyłam...
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <RiSave3Fill className="mr-2 text-2xl" /> Zapisz
                    </div>
                  )}
                </p>
              </div>
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddProduct;
