import { useDispatch } from "react-redux";
import { useSignIn } from "react-auth-kit";

import { useForm } from "react-hook-form";
import { useLoginUserMutation } from "../features/authApiSlice";
import { useNavigate } from "react-router-dom";

export default function Auth() {
  const signIn = useSignIn();
  const [
    loginUser,
    {
      data: loginData,
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      error: loginError,
    },
  ] = useLoginUserMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      loginUser({
        email: data.email,
        password: data.password,
      })
        .then((result) =>
          signIn({
            token: result.data[0].token.token,
            expiresIn: 3600,
            tokenType: "Bearer",
            authState: result.data[0].token.authState.userId,
          })
        )
        .then(() => navigate("/dashboard"))
        .catch((err) => console.log(err));
    } catch (error) {
      // Show error message
    }

    // await loginUser({
    //   email: data.email,
    //   password: data.password,
    // });
    // .unwrap()
    // .then((payload) => console.log("fulfilled", payload))
    // .catch((error) => console.error("rejected", error));
    // reset(); //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  };

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen bg-slate-200">
      <form
        className="flex flex-col items-center justify-center p-10 bg-white rounded shadow-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="mb-5 text-3xl text-gray-600 uppercase">Login</p>
        <div className="flex flex-col mb-5">
          <input
            id="email"
            className="p-3 border-2 rounded outline-none w-80 focus:border-my-green"
            autoComplete="off"
            placeholder="Email"
            {...register("email", {
              required: "Pole wymagane!",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Nieprawidłowy adres Email",
              },
            })}
            type="email"
          />
          {errors.email && (
            <span className="w-full px-4 text-white bg-red-700" role="alert">
              {errors.email.message}
            </span>
          )}
        </div>
        <div className="flex flex-col mb-5">
          <input
            className="p-3 border-2 rounded outline-none w-80 focus:border-my-green"
            autoComplete="off"
            placeholder="Hasło"
            id="password"
            {...register("password", {
              required: "Pole wymagane!",
              minLength: {
                value: 6,
                message: "Minimalna długość to 8 znaków",
              },
            })}
            type="password"
          />
          {errors.password && (
            <span className="w-full px-4 text-white bg-red-700" role="alert">
              {errors.password.message}
            </span>
          )}
        </div>
        <button
          className="p-2 font-bold text-white duration-300 transform rounded bg-my-green hover:bg-my-dark-green02 w-80"
          type="submit"
        >
          SUBMIT
        </button>
      </form>
    </div>
  );
}
