import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useGetAllProductsQuery } from "../../features/productsSlice";
import { AiOutlineLoading } from "react-icons/ai";
import { ImSad } from "react-icons/im";

import Product from "./Product";
import Loader from "../../components/Loader";

export default function Products() {
  const { data: productsData, error, isLoading } = useGetAllProductsQuery();
  // const productsData = useSelector(selectAllProducts);

  if (isLoading) return <Loader />;
  if (error) return <>Error!!!</>;
  return (
    <div className="flex flex-col items-start justify-start w-full">
      <table className="flex flex-col min-w-full leading-normal">
        <thead>
          <tr className="flex w-full">
            <th className="flex-1 px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
              Produkt
            </th>
            <th className="flex-1 px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
              Kategoria
            </th>
            <th className="flex-1 px-5 py-3 text-xs font-semibold tracking-wider text-left text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
              Cena
            </th>
            <th className="flex-1 px-5 py-3 text-xs font-semibold tracking-wider text-center text-gray-600 uppercase bg-gray-100 border-b-2 border-gray-200">
              Akcje
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr className="flex flex-col">
              <td
                className="flex-col items-center justify-center w-full py-4 uppercase text-my-dark-green02"
                colSpan="6"
              >
                <AiOutlineLoading className="w-full my-5 text-9xl animate-spin" />
                <span className="text-4xl font-thin">Wcztuję produkty</span>
              </td>
            </tr>
          )}
          {productsData?.length === 0 && !isLoading && (
            <tr className="flex flex-col">
              <td
                className="flex-col items-center justify-center w-full py-4 uppercase text-my-dark-green02"
                colSpan="6"
              >
                <ImSad className="w-full my-5 text-9xl" />
                <span className="text-4xl font-thin">Brak Produktów</span>
              </td>
            </tr>
          )}

          {productsData?.map((product) => (
            <Product key={product.id} product={product} modal={"setModal"} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
