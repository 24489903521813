import { RequireAuth } from "react-auth-kit";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Layout from "./components/Layout";
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
// import { useAppDispatch } from "./app/hooks";
// import { setUser } from "./features/authSlice";
import Products from "./pages/Products/";
import AddProduct from "./pages/Products/AddProduct";
import Orders from "./pages/Orders/";
import Page from "./pages/Page";
import Slider from "./pages/Slider";
import AddSlider from "./pages/Slider/AddSlider";
import Banners from "./pages/Banners";
import AddBanners from "./pages/Banners/AddBaner";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/auth" replace />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/" element={<Layout />}>
            <Route
              path="/dashboard"
              element={
                <RequireAuth loginPath={"/auth"}>
                  <Dashboard />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/products"
              element={
                <RequireAuth loginPath={"/auth"}>
                  <Products />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/addproduct"
              element={
                <RequireAuth loginPath={"/auth"}>
                  <AddProduct />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/orders"
              element={
                <RequireAuth loginPath={"/auth"}>
                  <Orders />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/onas"
              element={
                <RequireAuth loginPath={"/auth"}>
                  <Page page="onas" />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/regulamin"
              element={
                <RequireAuth loginPath={"/auth"}>
                  <Page page="regulamin" />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/start"
              element={
                <RequireAuth loginPath={"/auth"}>
                  <Slider />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/dodajstart"
              element={
                <RequireAuth loginPath={"/auth"}>
                  <AddSlider />{" "}
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/banery"
              element={
                <RequireAuth loginPath={"/auth"}>
                  <Banners />{" "}
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/dodajbanery"
              element={
                <RequireAuth loginPath={"/auth"}>
                  <AddBanners />{" "}
                </RequireAuth>
              }
              exact
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
