import React, { useState } from "react";
import UserMenu from "./UserMenu";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { logOut, selectAuth } from "../../features/authSlice";
import { useSignOut } from "react-auth-kit";

function Header({ sidebarOpen, setSidebarOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const signOut = useSignOut();
  const handleLogout = () => {
    signOut();
    navigate("/auth");
  };

  return (
    <header className="sticky top-0 z-30 py-2 bg-white border-b border-slate-200">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Header: Left side */}
          <img src="./images/logo.png" alt="logo" className="h-full py-1" />
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            <button
              onClick={() => handleLogout()}
              className="py-2.5 px-5 mr-2 text-sm font-medium text-white focus:outline-none bg-my-dark-green02 rounded-lg border border-my-dark-green02 hover:bg-gray-100 hover:text-my-dark-green focus:z-10 focus:ring-4 focus:ring-gray-200 "
            >
              Wyloguj
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
