import { forwardRef } from "react";
import SunEditor from "suneditor-react";

import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { useController } from "react-hook-form";

const ButtonCustomList = [
  ["undo", "redo"],
  ["table"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["horizontalRule", "list"],
  ["link"],
  ["removeFormat"],
];

const Editor = forwardRef(({ control, name, ...props }) => {
  const {
    field: { value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: "",
  });

  return (
    <SunEditor
      {...props}
      {...inputProps}
      setContents={value}
      autoFocus={false}
      lang="pl"
      setDefaultStyle="font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont; font-weight: 400; margin-bottom:1.5rem; height:300px"
      setOptions={{
        buttonList: ButtonCustomList,
      }}
    />
  );
});

export default Editor;
