import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "../components/DatePicker";

import {
  format,
  isBefore,
  isAfter,
  subDays,
  addDays,
  parseISO,
} from "date-fns";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/auth");
  };

  const [dateFrom, setDateFrom] = useState(
    new Date(new Date().setDate(new Date().getDate() - 14))
  );

  const [dateTo, setDateTo] = useState(new Date());
  const dateOptionsSameDate = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const orders = [];
  const ThisWeekOrders = orders.filter(
    (item) =>
      format(new Date(item.createdAt), "w/y") === format(new Date(), "w/y")
  );
  const ThisMonthOrders = orders.filter(
    (item) =>
      format(new Date(item.createdAt), "M/y") === format(new Date(), "M/y")
  );
  const TodayOrders = orders.filter(
    (item) =>
      format(new Date(item.createdAt), "d/M/y") === format(new Date(), "d/M/y")
  );

  return (
    <div className="flex flex-col items-center justify-start w-full min-h-screen">
      <div className="flex justify-between w-full mb-2">
        <DatePicker
          afterLimit={dateTo}
          selectedDate={dateFrom}
          setSelectedDate={setDateFrom}
          label="Od"
        />

        <DatePicker
          beforeLimit={dateFrom}
          selectedDate={dateTo}
          setSelectedDate={setDateTo}
          label="Do"
        />
      </div>
      <button
        className="p-4 text-white bg-my-green"
        onClick={() => handleLogout()}
      >
        Logout
      </button>
    </div>
  );
}
