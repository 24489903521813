import { useDispatch } from "react-redux";
import { openModal } from "../../features/modalSlice";
import { RiEdit2Line, RiDeleteBin5Line } from "react-icons/ri";

export default function Order({ order }) {
  const dispatch = useDispatch();
  const { ID_Zamowienia, AdresZamowien, Kwota, Data_zamowienia, Status } =
    order;
  return (
    <tr className="flex w-full border-b border-slate-200">
      <td className="flex-1 px-5 py-2 text-sm text-left">{ID_Zamowienia}</td>
      <td className="flex-1 px-5 py-2 text-sm text-left">{Data_zamowienia}</td>
      <td className="flex-1 px-5 py-2 text-sm text-left">
        {AdresZamowien.Adres} | {AdresZamowien.KodPocztowy}{" "}
        {AdresZamowien.Miasto}
      </td>
      <td className="flex-1 px-5 py-2 text-sm text-left">{Kwota}</td>
      <td className="flex-1 px-5 py-2 text-sm text-center">{Status}</td>
      <td className="flex-1 px-5 py-2 text-sm text-center">
        <RiEdit2Line
          onClick={() =>
            dispatch(
              openModal({
                open: true,
                type: "Order",
                title: "Szczegóły Zamówienia",
                body: order,
              })
            )
          }
          className="p-2 mr-2 text-4xl text-blue-600 duration-150 rounded-full cursor-pointer bg-slate-200 hover:bg-blue-600 hover:text-slate-200 hover:scale-110 "
          data-bs-toggle="collapse"
          data-bs-target={ID_Zamowienia}
          aria-expanded="false"
          aria-controls={ID_Zamowienia}
        />
      </td>
    </tr>
  );
}
