import React from "react";
import { useDispatch } from "react-redux";
import { RiEdit2Line, RiDeleteBin5Line } from "react-icons/ri";
import { useDelProductMutation } from "../../features/productsSlice";
import { openModal } from "../../features/modalSlice";

export default function Product({ product, modal }) {
  const [delProduct] = useDelProductMutation();
  const dispatch = useDispatch();
  return (
    <>
      <tr className="flex items-stretch justify-start hover:bg-gray-100">
        <td className="flex-1 px-5 py-2 text-sm border-b border-gray-200">
          <div className="flex items-center">
            <div className="w-20 h-20">
              <img
                className="w-full h-full rounded-full bg-slate-200"
                src={
                  product.image &&
                  `https://phlabshop.com/pic/${product.image[0]}`
                }
                alt=""
              />
            </div>
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">
                {product.title}
              </p>
            </div>
          </div>
        </td>
        <td className="flex-1 px-5 py-2 border-b border-gray-200 ">
          <p className="text-sm text-left text-gray-900 whitespace-no-wrap">
            {product.category}
          </p>
        </td>
        <td className="flex-1 px-5 py-2 border-b border-gray-200">
          <p className="text-sm font-semibold text-left text-green-900 whitespace-no-wrap">
            {product.price} PLN
          </p>
        </td>
        <td className="flex-1 px-5 py-2 border-b border-gray-200">
          <span className="flex flex-row justify-center">
            <RiEdit2Line
              onClick={() =>
                dispatch(
                  openModal({
                    open: true,
                    type: "Product",
                    title: "Edytuj Produkt",
                    body: product,
                  })
                )
              }
              className="p-2 mr-2 text-4xl text-blue-600 duration-150 rounded-full cursor-pointer bg-slate-200 hover:bg-blue-600 hover:text-slate-200 hover:scale-110 "
              data-bs-toggle="collapse"
              data-bs-target={product._id}
              aria-expanded="false"
              aria-controls={product._id}
            />
            <RiDeleteBin5Line
              onClick={() => delProduct(product.id)}
              className="p-2 ml-2 text-4xl text-red-700 duration-150 rounded-full cursor-pointer bg-slate-200 hover:bg-red-700 hover:text-slate-200 hover:scale-110"
            />
          </span>
        </td>
      </tr>
    </>
  );
}
