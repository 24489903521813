import { apiSlice } from "./apiSlice";
import { createSelector, createSlice } from "@reduxjs/toolkit";

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: () => "/products",
      providesTags: ["Product"],
    }),
    addProduct: builder.mutation({
      query: (product) => ({
        url: "/products",
        // headers: { "Content-Type": "multipart/form-data" },
        method: "POST",
        body: product,
      }),
      invalidatesTags: ["Product"],
    }),
    delProduct: builder.mutation({
      query: (id) => ({
        url: `/products/`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: ["Product"],
    }),
    addProductImage: builder.mutation({
      query: (image) => ({
        url: "/add_product_image/",
        // headers: { "Content-Type": "multipart/form-data" },
        method: "POST",
        body: image,
      }),
      invalidatesTags: ["Product"],
    }),
    removeProductImage: builder.mutation({
      query: (image) => ({
        url: "/remove_product_image/",
        // headers: { "Content-Type": "multipart/form-data" },
        method: "DELETE",
        body: image,
      }),
      invalidatesTags: ["Product"],
    }),
    editProduct: builder.mutation({
      query: (product) => ({
        url: `/products`,
        method: "PUT",
        body: product,
      }),
      invalidatesTags: ["Product"],
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useAddProductMutation,
  useAddProductImageMutation,
  useRemoveProductImageMutation,
  useEditProductMutation,
  useDelProductMutation,
  useLazyGetAllProductsQuery,
} = productsApiSlice;

export const productsSlice = createSlice({
  name: "Products",
  initialState: {
    items: [],
    selected: false,
    loading: false,
    error: null,
    page: 1,
  },
  reducers: {
    selectProduct: (state, action) => {
      state.selected = action.payload;
    },
    clearSelectedProduct: (state) => {
      state.selected = false;
    },
  },
});

export const itemImagesSelector = (Id) =>
  createSelector(
    (state) => state.products.items,
    (items) => items.filter((item) => item._id === Id).image
  );

export const { getProducts, selectProduct, clearSelectedProduct } =
  productsSlice.actions;

export default productsSlice.reducer;
