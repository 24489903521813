import { apiSlice } from "./apiSlice";
import { createSelector, createSlice } from "@reduxjs/toolkit";

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: () => "/orders",
      providesTags: ["Order"],
    }),
    editOrder: builder.mutation({
      query: (product) => ({
        url: `/order/`,
        method: "PUT",
        body: product,
      }),
      invalidatesTags: ["Order"],
    }),
  }),
});

export const {
  useGetAllOrdersQuery,
  useEditOrderMutation,
  useLazyGetAllOrdersQuery,
} = ordersApiSlice;

export const ordersSlice = createSlice({
  name: "Orders",
  initialState: {
    items: [],
    selected: false,
    loading: false,
    error: null,
    page: 1,
  },
  reducers: {
    selectOrder: (state, action) => {
      state.selected = action.payload;
    },
    clearSelectedOrder: (state) => {
      state.selected = false;
    },
  },
});

export const itemImagesSelector = (Id) =>
  createSelector(
    (state) => state.orders.items,
    (items) => items.filter((item) => item._id === Id).image
  );

export const { getOrders, selectOrder, clearSelectedOrder } =
  ordersSlice.actions;

export default ordersSlice.reducer;
