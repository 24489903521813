import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import {
  useEditProductMutation,
  useAddProductImageMutation,
  useRemoveProductImageMutation,
} from "../../features/productsSlice";
import Editor from "../Editor";
import Resizer from "react-image-file-resizer";

import { openModal } from "../../features/modalSlice";

import { AiOutlineCloudUpload, AiOutlineLoading } from "react-icons/ai";
import { RiSave3Fill, RiDeleteBin6Line } from "react-icons/ri";

const EditProduct = ({ product }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [editProduct, { isLoading }] = useEditProductMutation();
  const [addProductImage, { isLoading: addingImage }] =
    useAddProductImageMutation();

  const [productImages, setProductImages] = useState(null);
  const navigate = useNavigate();

  const [removeProductImage, { isLoading: removingImage }] =
    useRemoveProductImageMutation();
  // const removeProductImage = (e) => console.log(e);

  useEffect(() => {
    (async () => await setProductImages(product.images))();
  }, [product]);

  const addImage = async (data) => {
    const formData = new FormData();
    data.map((e, i) => formData.append(`image_${i}`, e));

    formData.append("id", product.id);
    await addProductImage(formData).then((res) => {
      setProductImages(res.data.images);
    });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  const onSubmit = async (data) => {
    data.id = product.id;
    editProduct(data)
      .then(() => dispatch(openModal({ open: false, type: null, body: null })))
      .then(() => navigate("/products"));
  };

  return (
    <div className="flex flex-row gap-x-4">
      <div className="flex-1 p-8 border rounded-3xl border-slate-200">
        <div className="flex items-start justify-center w-full">
          {addingImage || removingImage ? (
            <AiOutlineLoading className="text-6xl animate-spin text-lime-700" />
          ) : (
            <div className="flex items-center justify-center w-full bg-grey-lighter">
              <label className="flex items-center justify-center w-full h-20 py-1 font-bold duration-300 border-2 border-dashed rounded-lg cursor-pointer group text-my-green items-starts bg-slate-100 hover:bg-my-green border-my-green hover:border-white hover:text-white">
                <AiOutlineCloudUpload className="mr-2 text-4xl" />
                <div className="mt-2 text-base leading-normal text-gray-600">
                  Dodaj zdjęcie{" "}
                  <span className="duration-300 transform text-my-green group-hover:text-white">
                    Klik
                  </span>
                </div>
                <input
                  type="file"
                  className="hidden"
                  multiple
                  accept=".jpg, .png"
                  onChange={(file) => {
                    const files = file.target.files;
                    const promises = [...files].map((el) => {
                      const output = resizeFile(el);
                      return output;
                    });
                    Promise.all(promises).then(
                      async (res) => await addImage(res)
                    );
                  }}
                />
              </label>
            </div>
          )}
        </div>

        <div className="container grid grid-flow-row grid-cols-5 gap-4 mx-auto mt-8">
          {removingImage || addingImage ? (
            <div className="flex justify-center w-full">
              <AiOutlineLoading className="mt-48 text-6xl animate-spin text-lime-700" />
            </div>
          ) : (
            productImages?.map((el) => {
              return (
                <div
                  key={`${el}_${el.id}`}
                  className="relative flex w-full h-24 bg-slate-200 rounded-2xl hover:border-grey-900 hover:border"
                >
                  <img
                    src={`http://phlabshop.com/pic/${el.filename}`}
                    className="w-full h-auto rounded-2xl"
                    alt={el.filename}
                  />

                  <button
                    onClick={async () =>
                      await removeProductImage({
                        id: el.id,
                        filename: el.filename,
                      }).then((res) => setProductImages(res.data.image))
                    }
                    className="absolute flex items-center justify-center w-12 h-12 text-2xl bg-white rounded-full bottom-2 right-2 text-slate-500"
                  >
                    <RiDeleteBin6Line />
                  </button>
                </div>
              );
            })
            // productImages.lenghth > 0 &&
            // productImages?.map((el) => (
            //   <div
            //     key={`${el}_${el.id}`}
            //     className="relative flex w-full h-24 bg-slate-200 rounded-2xl hover:border-grey-900 hover:border"
            //   >
            //     <img
            //       src={`http://phlab.pl/pic/${el.filename}`}
            //       className="w-full h-auto rounded-2xl"
            //       alt={el}
            //     />

            //     <button
            //       onClick={
            //         async () => console.log(el)
            //         // await removeProductImage({
            //         //   id: el.ID_Photo,
            //         //   filename: el.Filename,
            //         // }).then((res) => setProductImages(res.data.image))
            //       }
            //       className="absolute flex items-center justify-center w-12 h-12 text-2xl bg-white rounded-full bottom-2 right-2 text-slate-500"
            //     >
            //       <RiDeleteBin6Line />
            //     </button>
            //   </div>
            // ))
          )}
        </div>
      </div>
      <form
        className="flex-1 w-1/2 p-8 border rounded-2xl border-slate-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col items-start justify-start mb-3">
          <label className="block mb-2 text-my-dark-green" htmlFor="title">
            Nazwa produktu:
          </label>
          <input
            disabled={isLoading}
            className="w-full p-2 mb-2 text-base leading-4 text-gray-600 placeholder-gray-600 border border-gray-300 rounded "
            name="title"
            defaultValue={product.title}
            placeholder="Nazwa Produktu"
            {...register("title", { required: true })}
          />
          <label className="block mb-2 text-my-dark-green" htmlFor="category">
            Kategoria:
          </label>
          <input
            disabled={isLoading}
            className="w-full p-2 mb-2 text-base leading-4 text-gray-600 placeholder-gray-600 border border-gray-300 rounded "
            name="category"
            defaultValue={product.category}
            placeholder="Kategoria Produktu"
            {...register("category", { required: true })}
          />
          <label className="block mb-2 text-my-dark-green" htmlFor="price">
            Cena:
          </label>
          <input
            disabled={isLoading}
            className="w-full p-2 mb-2 text-base leading-4 text-gray-600 placeholder-gray-600 border border-gray-300 rounded "
            name="price"
            type="number"
            defaultValue={product.price}
            placeholder="Cena"
            {...register("price", { required: true })}
          />
          <label
            className="block mb-2 text-my-dark-green"
            htmlFor="description"
          >
            Opis produktu:
          </label>

          <Editor
            name="description"
            control={control}
            defaultValue={product.description}
          />
        </div>

        <button
          disabled={isLoading}
          type="submit"
          className="flex items-center justify-center w-full py-2 text-white duration-150 border border-transparent rounded bg-lime-900 hover:bg-lime-700"
        >
          <div>
            <p className="text-base leading-4 ">
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <AiOutlineLoading className="mr-2 text-2xl animate-spin" />
                  Wysyłam...
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <RiSave3Fill className="mr-2 text-2xl" /> Zapisz
                </div>
              )}
            </p>
          </div>
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
