import { useEffect } from "react";
import { useGetPageQuery } from "../features/pagesSlice";

import { useForm } from "react-hook-form";

import Editor from "./Editor";

import { AiOutlineLoading } from "react-icons/ai";
import { RiSave3Fill } from "react-icons/ri";

import Loader from "../components/Loader";
export default function Page({ page }) {
  const { data: pageContent, isLoading: loadingPages } = useGetPageQuery(page);

  const { register, handleSubmit, control, setValue } = useForm();

  useEffect(() => {
    if (pageContent) {
      setValue("description", pageContent.tresc);
    }
  }, [pageContent, setValue]);

  const isLoading = false;

  const onSubmit = async (data) => {
    console.log(data);
  };

  if (loadingPages) return <Loader />;
  return (
    <div>
      <h1 className="flex items-center justify-between px-4 py-2 mb-4 text-2xl rounded-lg bg-slate-100 text-my-dark-green font-extralight text-my-dark-greeen">
        {pageContent.tytul}
      </h1>

      <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
        <Editor name="description" control={control} />
        <button
          disabled={isLoading}
          type="submit"
          className="flex items-center justify-center w-full py-2 mt-3 font-semibold duration-150 border border-transparent rounded-lg text-slate-100 bg-my-dark-green02 hover:bg-lime-700"
        >
          <div>
            <div className="text-base leading-4 ">
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <AiOutlineLoading className="mr-2 text-2xl animate-spin" />
                  Wysyłam...
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <RiSave3Fill className="mr-2 text-2xl" /> Zapisz
                </div>
              )}
            </div>
          </div>
        </button>
      </form>
    </div>
  );
}
