import { useState } from "react";
import { useSelector } from "react-redux";

import Modal from "./Modal";
import LoadingToRedirect from "./LoadingToRedirect";
import Header from "../pages/Header";
import Sidebar from "../pages/SideBar";
import { Outlet } from "react-router-dom";

export default function PrivateRoute() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { modal } = useSelector((state) => state);

  return (
    <div className="flex max-h-screen">
      {modal.open && <Modal />}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-scroll">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="w-full px-4 py-8 mx-auto sm:px-6 lg:px-10 max-w-9xl ">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}
