import React, { forwardRef, useEffect, useState } from "react";
import {
  format,
  subDays,
  addDays,
  subMonths,
  addMonths,
  subYears,
  addYears,
  isEqual,
  isBefore,
  isAfter,
  getDaysInMonth,
  getDay,
  getDate,
  subWeeks,
  addWeeks,
} from "date-fns";
import { pl } from "date-fns/locale";

export default function Datepicker({
  selectedDate,
  setSelectedDate,
  label,
  beforeLimit,
  afterLimit = new Date(),
}) {
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState(new Date());
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [showYearPicker, setShowYearPicker] = useState(false);

  const [dayCount, setDayCount] = useState([]);
  const [blankDays, setBlankDays] = useState([]);

  const [type, setType] = useState("date");

  const decrement = () => {
    switch (type) {
      case "date":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      case "month":
        setDatepickerHeaderDate((prev) => subYears(prev, 1));
        break;
      case "year":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      default:
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
    }
  };

  const increment = () => {
    switch (type) {
      case "date":
        setDatepickerHeaderDate((prev) => addMonths(prev, 1));
        break;
      case "month":
        setDatepickerHeaderDate((prev) => addYears(prev, 1));
        break;
      case "year":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      default:
        setDatepickerHeaderDate((prev) => addMonths(prev, 1));
    }
  };

  const getDayCount = (date) => {
    let daysInMonth = getDaysInMonth(date);
    // // find where to start calendar day of week
    let dayOfWeek = getDay(new Date(date.getFullYear(), date.getMonth(), 1));
    let blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }
    let daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }
    setBlankDays(blankdaysArray);
    setDayCount(daysArray);
  };

  useEffect(() => {
    getDayCount(datepickerHeaderDate);
  }, [datepickerHeaderDate]);

  useEffect(() => {
    setDatepickerHeaderDate(selectedDate);
  }, [selectedDate]);

  const toggleDatepicker = () => setShowDatepicker((prev) => !prev);

  const setDateValue = (date) => {
    setSelectedDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        date
      )
    );
    setShowDatepicker(false);
  };

  const showMonthPicker = () => {
    setType("month");
  };
  const isToday = (date) => {
    return isEqual(
      new Date(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        date
      ),
      selectedDate.setHours(0, 0, 0, 0)
    );
  };

  const isAfterLimit = (date) =>
    isAfter(
      new Date(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        date
      ),
      afterLimit
    );

  const isBeforeLimit = (date) =>
    isBefore(
      new Date(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        date
      ),
      beforeLimit
    );

  const isSelectedMonth = (month) =>
    isEqual(
      new Date(selectedDate.getFullYear(), month, selectedDate.getDate()),
      selectedDate
    );

  const setMonthValue = (month) => {
    setDatepickerHeaderDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        month,
        datepickerHeaderDate.getDate()
      )
    );
    setType("date");
  };
  return (
    <div className="relative flex flex-col items-start">
      <label
        htmlFor="datepicker"
        className="block mb-1 text-sm font-bold text-my-dark-green"
      >
        Zakres {label}:
      </label>
      <div className="flex items-baseline justify-between p-1 px-2 text-gray-900 bg-gray-100 rounded-lg gap-x-2 ">
        <button
          className="px-2 font-bold text-white rounded-lg bg-my-dark-green02 disabled:bg-slate-200"
          onClick={() => setSelectedDate((prev) => subDays(prev, 1))}
          disabled={isBeforeLimit(getDate(selectedDate) - 2)}
        >
          - 1
        </button>
        <button
          className="px-2 font-bold text-white rounded-lg bg-my-dark-green02 disabled:bg-slate-200"
          onClick={() => setSelectedDate((prev) => subWeeks(prev, 1))}
          disabled={isBeforeLimit(getDate(selectedDate) - 9)}
        >
          - 7
        </button>
        <div className="relative">
          <input type="hidden" name="date" />
          <input
            type="text"
            readOnly
            className="w-full py-1 pl-4 pr-10 text-sm font-bold leading-none text-gray-900 border-0 rounded-md cursor-pointer focus:ring-my-green focus:border-my-green"
            placeholder="Select date"
            value={format(selectedDate, "dd/MM/yyyy")}
            onClick={toggleDatepicker}
          />
          <div
            className="absolute right-0 px-2 py-1 cursor-pointer -top-0"
            onClick={toggleDatepicker}
          >
            <svg
              className="w-5 h-5 text-my-dark-green"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
          {showDatepicker && (
            <div
              className="absolute top-0 z-50 p-4 mt-8 bg-white rounded-lg shadow -left-1"
              style={{ width: "17rem" }}
            >
              <div className="flex items-center justify-between mb-2">
                <div>
                  <button
                    type="button"
                    className="inline-flex p-1 transition duration-100 ease-in-out rounded-full cursor-pointer hover:bg-gray-200"
                    onClick={decrement}
                  >
                    <svg
                      className="inline-flex w-6 h-6 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>
                </div>
                {type === "date" && (
                  <div
                    onClick={() => showMonthPicker()}
                    className="flex-grow p-1 text-lg font-bold text-gray-800 rounded-lg cursor-pointer hover:bg-gray-200"
                  >
                    <p className="text-center">
                      {format(datepickerHeaderDate, "MMMM", {
                        locale: pl,
                      })}
                    </p>
                  </div>
                )}
                <div
                  // onClick={showYearPicker}
                  className="flex-grow p-1 text-lg font-bold text-gray-800 rounded-lg"
                >
                  <p className="text-center">
                    {format(datepickerHeaderDate, "yyyy")}
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    className="inline-flex p-1 transition duration-100 ease-in-out rounded-full cursor-pointer hover:bg-gray-200"
                    onClick={increment}
                  >
                    <svg
                      className="inline-flex w-6 h-6 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {type === "date" && (
                <>
                  <div className="flex flex-wrap mb-3 -mx-1">
                    {[
                      "pon.",
                      " wt.",
                      "śr.",
                      "czw.",
                      "pt.",
                      "sob.",
                      "niedz.",
                    ].map((day, i) => (
                      <div key={i} style={{ width: "14.26%" }} className="px-1">
                        <div className="text-xs font-medium text-center text-gray-800">
                          {day}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-wrap -mx-1">
                    {blankDays.map((_, i) => (
                      <div
                        key={i}
                        style={{ width: "14.26%" }}
                        className="p-1 text-sm text-center border border-transparent"
                      ></div>
                    ))}
                    {dayCount.map((d, i) => (
                      <div
                        key={i}
                        style={{ width: "14.26%" }}
                        className="px-1 mb-1"
                      >
                        <div
                          onClick={() =>
                            !isBeforeLimit(d) &&
                            !isAfterLimit(d) &&
                            setDateValue(d)
                          }
                          className={`p-1 text-center text-sm leading-none rounded-lg transition ease-in-out duration-100 ${
                            isToday(d)
                              ? "bg-my-dark-green02 text-white font-bold"
                              : isAfterLimit(d) || isBeforeLimit(d)
                              ? "text-gray-300"
                              : "text-gray-600 hover:bg-green-100 cursor-pointer"
                          }`}
                        >
                          {d}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {type === "month" && (
                <div className="flex flex-wrap -mx-1">
                  {Array(12)
                    .fill(null)
                    .map((_, i) => (
                      <div
                        key={i}
                        onClick={() => setMonthValue(i)}
                        style={{ width: "25%" }}
                      >
                        <div
                          className={`cursor-pointer p-5 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 ${
                            isSelectedMonth(i)
                              ? "bg-my-dark-green02 text-white"
                              : "text-gray-700 hover:bg-blue-200"
                          }`}
                        >
                          {format(
                            new Date(
                              datepickerHeaderDate.getFullYear(),
                              i,
                              datepickerHeaderDate.getDate()
                            ),
                            "MMM",
                            {
                              locale: pl,
                            }
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
        <button
          className="px-2 font-bold text-white rounded-lg bg-my-dark-green02 disabled:bg-slate-200"
          onClick={() => setSelectedDate((prev) => addDays(prev, 1))}
          disabled={isAfterLimit(getDate(selectedDate) + 2)}
        >
          + 1
        </button>
        <button
          className="px-2 font-bold text-white rounded-lg bg-my-dark-green02 disabled:bg-slate-200"
          onClick={() => setSelectedDate((prev) => addWeeks(prev, 1))}
          disabled={isAfterLimit(getDate(selectedDate) + 9)}
        >
          + 7
        </button>
      </div>
    </div>
  );
}
