import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../features/modalSlice";
import EditProduct from "../pages/Products//EditProduct";
import EditOrder from "../pages/Orders/EditOrder";
import EditSlider from "../pages/Slider/EditSlider";
import EditBanner from "../pages/Banners/EditBanner";

function Modal() {
  const { modal } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [title, setTitle] = useState("");

  const modalContent = () => {
    switch (modal.type) {
      case "Product":
        return <EditProduct product={modal.body} />;
      case "Order":
        return <EditOrder order={modal.body} />;
      case "Slider":
        return <EditSlider slider={modal.body} />;
      case "Baners":
        return <EditBanner banner={modal.body} />;
      default:
        return "No niestety błąd";
    }
  };

  return (
    <div className="absolute top-0 left-0 z-50 w-screen min-h-screen overflow-hidden bg-black/75">
      <div className="absolute z-[55] flex flex-col items-start justify-start w-9/12 p-4 -translate-x-1/2  bg-white shadow-lg top-4 left-1/2 rounded-xl">
        <div className="flex items-center justify-between w-full ">
          <h1 className="flex items-center justify-between w-full px-4 py-2 text-xl font-light text-left uppercase rounded-lg text-my-dark-green02 bg-slate-200">
            {modal.title}
            <svg
              onClick={() =>
                dispatch(
                  openModal({
                    open: false,
                    type: null,
                    body: null,
                  })
                )
              }
              version="1.1"
              viewBox="0 0 512 512"
              className="w-10 h-10 duration-300 transform cursor-pointer fill-my-green hover:fill-my-dark-green"
            >
              <g>
                <path d="M256,33C132.3,33,32,133.3,32,257c0,123.7,100.3,224,224,224c123.7,0,224-100.3,224-224C480,133.3,379.7,33,256,33z    M364.3,332.5c1.5,1.5,2.3,3.5,2.3,5.6c0,2.1-0.8,4.2-2.3,5.6l-21.6,21.7c-1.6,1.6-3.6,2.3-5.6,2.3c-2,0-4.1-0.8-5.6-2.3L256,289.8   l-75.4,75.7c-1.5,1.6-3.6,2.3-5.6,2.3c-2,0-4.1-0.8-5.6-2.3l-21.6-21.7c-1.5-1.5-2.3-3.5-2.3-5.6c0-2.1,0.8-4.2,2.3-5.6l75.7-76   l-75.9-75c-3.1-3.1-3.1-8.2,0-11.3l21.6-21.7c1.5-1.5,3.5-2.3,5.6-2.3c2.1,0,4.1,0.8,5.6,2.3l75.7,74.7l75.7-74.7   c1.5-1.5,3.5-2.3,5.6-2.3c2.1,0,4.1,0.8,5.6,2.3l21.6,21.7c3.1,3.1,3.1,8.2,0,11.3l-75.9,75L364.3,332.5z" />
              </g>
            </svg>
          </h1>
        </div>
        <div className="px-4 mt-6">{modalContent()}</div>
      </div>
    </div>
  );
}

export default Modal;
