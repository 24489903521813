import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  AiFillHome,
  AiOutlineAreaChart,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { BsShop, BsFileText } from "react-icons/bs";
import { TiShoppingCart } from "react-icons/ti";
import { IoMdAddCircle } from "react-icons/io";
// import SidebarLinkGroup from "./SidebarLinkGroup";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  const [openProdukty, setOpenProdukty] = useState(false);
  const [openStart, setOpenStart] = useState(false);
  const [openBanery, setOpenBanery] = useState(false);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0  bg-my-dark-green z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      />

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0  bg-my-dark-green02 shadow-[inset_-10px_0px_10px_0px_rgba(0,0,0,0.3)]  p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between pr-3 mb-10 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="block text-white">
            <AiFillHome />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="pl-3 text-xs font-semibold text-white uppercase">
              <span
                className="hidden w-6 text-center lg:block lg:sidebar-expanded:hidden 2xl:hidden"
                aria-hidden="true"
              >
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                Pages
              </span>
            </h3>
            <ul className="mt-3">
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === "/" && "bg-lime-900"
                }`}
              >
                <NavLink
                  end
                  to="/dashboard"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname === "/" && "hover:text-slate-200"
                  }`}
                >
                  <div className="flex items-center">
                    <AiOutlineAreaChart />
                    <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                      Dashboard
                    </span>
                  </div>
                </NavLink>
              </li>
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0`}>
                <button
                  className="flex items-center justify-between w-full text-white"
                  onClick={() => setOpenProdukty((prv) => !prv)}
                >
                  <div className="flex">
                    <BsShop className="" />
                    <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                      Produkty
                    </span>
                  </div>
                  <svg
                    className={`w-6 h-6 transform duration-150  ${
                      openProdukty && "rotate-180"
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                {openProdukty && (
                  <ul className="pt-2">
                    <li
                      className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        pathname === "/produkty" && "bg-lime-900"
                      }`}
                    >
                      <NavLink
                        end
                        to="/products"
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                          pathname === "/produkty" && "hover:text-slate-200"
                        }`}
                      >
                        <div className="flex items-center">
                          <AiOutlineUnorderedList />
                          <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                            Lista
                          </span>
                        </div>
                      </NavLink>
                    </li>

                    <li
                      className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                        pathname === "/dodajprodukt" && "bg-lime-900"
                      }`}
                    >
                      <NavLink
                        end
                        to="/addproduct"
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                          pathname === "/dodajprodukt" && "hover:text-slate-200"
                        }`}
                      >
                        <div className="flex items-center">
                          <IoMdAddCircle />
                          <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                            Dodaj produkt!
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === "/zamowienia" && "bg-lime-900"
                }`}
              >
                <NavLink
                  end
                  to="/orders"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname === "/zamowienia" && "hover:text-slate-200"
                  }`}
                >
                  <div className="flex items-center">
                    <TiShoppingCart />
                    <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                      Zamówienia
                    </span>
                  </div>
                </NavLink>
              </li>
              <hr className="my-2 border-gray-200 border-dotted" />
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === "/onas" && "bg-lime-900"
                }`}
              >
                <NavLink
                  end
                  to="/onas"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname === "/onas" && "hover:text-slate-200"
                  }`}
                >
                  <div className="flex items-center">
                    <BsFileText />
                    <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                      O nas
                    </span>
                  </div>
                </NavLink>
              </li>
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === "/regulamin" && "bg-lime-900"
                }`}
              >
                <NavLink
                  end
                  to="/regulamin"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname === "/regulamin" && "hover:text-slate-200"
                  }`}
                >
                  <div className="flex items-center">
                    <BsFileText />
                    <span className="ml-3 text-sm font-medium duration-200 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                      Regulamin
                    </span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="justify-end hidden pt-3 mt-auto lg:inline-flex 2xl:hidden">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
