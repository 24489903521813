import { apiSlice } from "./apiSlice";
import { createSelector, createSlice } from "@reduxjs/toolkit";

export const pagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPages: builder.query({
      query: () => "/pages",
    }),
    getPage: builder.query({
      query: (page) => `/page/${page}`,
    }),
    editPage: builder.mutation({
      query: (page) => ({
        url: `/pages`,
        method: "PUT",
        body: page,
      }),
      invalidatesTags: ["Pages"],
    }),
  }),
});
export const { useGetAllPagesQuery, useGetPageQuery, useEditPageMutation } =
  pagesApiSlice;
