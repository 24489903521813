import React from "react";

const EditOrder = ({ order }) => {
  return (
    <div className="flex flex-row w-full gap-x-4">
      <div className="w-1/2">
        <div className="flex flex-col items-start justify-center w-full mb-4">
          {order.Szczegoly_zamowienia.map((item) => (
            <div>Produkt - {item.Cena_jednostkowa}</div>
          ))}
        </div>
      </div>
      <div className="w-1/2">
        <div className="flex items-start justify-center w-full mb-4">
          <ul>
            <li>
              Razem: <span className="font-bold"> {order.Kwota}</span>{" "}
            </li>
            <li>
              Data: <span className="font-bold">{order.Data_zamowienia}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
